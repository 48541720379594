<template>
	<el-dialog class="dialog" :title="title" :visible.sync="visiable" v-if="visiable" width="80%">
		<div v-loading="loading" class="dlalog-body">
			<div class="dia-title">商品销售信息</div>
			<el-form ref="form" :rules="rules" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="产品名称：" prop="name"><el-input v-model="formData.name" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="产品描述：" prop="prodDesc"><el-input v-model="formData.prodDesc" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :span="12">
						<el-form-item label="品牌：" prop="brandId">
							<el-select v-model="formData.brandId" placeholder="请选择品牌" @change="change1" :disabled="dialogObj.type == 0">
								<el-option v-for="item in options3" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="支付方式：" prop="email"><el-input value="转账汇款" disabled></el-input></el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="8">
						<el-form-item label="发货城市：" prop="provinces">
							<el-col :span="12">
								<el-cascader
									class="cascader"
									v-model="formData.provinces"
									clearable
									placeholder="请选择省/市/区"
									@change="CityChange"
									:props="{ value: 'name', label: 'name', children: 'child' }"
									:options="cityOptions"
									:disabled="dialogObj.type == 0"
								></el-cascader>
							</el-col>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="最少起租：" prop="minDay">
							<el-col :span="12"><el-input v-model="formData.minDay" type="number" :disabled="dialogObj.type == 0"></el-input></el-col>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="新旧状态：" prop="newOld">
							<el-radio-group v-model="formData.newOld" :disabled="dialogObj.type == 0">
								<el-radio :label="1">全新</el-radio>
								<el-radio :label="2">非全新</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="产品类别：" prop="category1Id">
							<el-select v-model="formData.category1Id" @change="category1IdChange" placeholder="请选择商品类别" :disabled="dialogObj.type == 0">
								<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12" v-show="options2.length > 0" prop="category2Id">
						<el-form-item label="类别分类：">
							<el-select v-model="formData.category2Id" placeholder="请选择商品类别" :disabled="dialogObj.type == 0">
								<el-option v-for="item in options2" :key="item.id" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="购买说明："><el-input type="textarea" v-model="formData.buyDesc" :disabled="dialogObj.type == 0"></el-input></el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-table
				ref="usertable"
				:data="skuList"
				border
				:header-cell-style="{ 'background-color': '#f9fafc' }"
				:cell-style="{ padding: 0 }"
				width="90%"
				style="margin-buttom: 20px;"
			>
				<el-table-column align="center" label="图片" show-overflow-tooltip>
					<template slot-scope="scope">
						<div style="width: 100%;" class="flex align-center jc-center">
							<el-image
								style="cursor: pointer;"
								@click="openInnerBox(scope.$index, scope.row.img)"
								v-if="scope.row.img"
								:src="photoURL + scope.row.img"
								fit="fill"
								class="avatar"
							></el-image>
							<el-button v-else type="primary" @click="openInnerBox(scope.$index, '')">点击上传图片</el-button>
						</div>
					</template>
				</el-table-column>
				<el-table-column align="center" label="型号" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-input v-model="scope.row.number" :disabled="dialogObj.type == 0" placeholder="请输入型号"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="租赁押金" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-input style="margin: 10px 0;" v-model="scope.row.deposit" type="number" :disabled="dialogObj.type == 0" placeholder="请输入租赁押金"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="租赁价格" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-input style="margin: 10px 0;" v-model="scope.row.price" type="number" :disabled="dialogObj.type == 0" placeholder="请输入租赁价格"></el-input>
					</template>
				</el-table-column>
				<el-table-column align="center" label="商品库存" show-overflow-tooltip>
					<template slot-scope="scope">
						<el-input v-model="scope.row.stock" type="number" :disabled="dialogObj.type == 0" placeholder="请输入商品库存"></el-input>
					</template>
				</el-table-column>
				<el-table-column fixed="right" align="center" label="操作" width="230" v-if="dialogObj.type != 0">
					<template slot-scope="scope">
						<div style="min-height:40px">
							<el-button type="text" v-if="scope.$index == skuList.length - 1" @click="addList(scope.row)">添加一行</el-button>
							<el-button type="text" v-if="skuList.length!=1" @click="remove(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
				<template slot="empty">
					<el-empty :image-size="100" description="暂无数据"></el-empty>
				</template>
			</el-table>
			<div class="dia-title">商品介绍信息</div>
			<div class="flex align-center jc-start ">
				<el-form>
					<el-form-item style="position: relative;">
						<template>
							<div class="flex align-center jc-start">
								<div>
									<div class="huixianImgBox" v-for="(item, i) in imgList2" :key="i" @click="deletaImgList2(i)">
										<el-image class="huixianImg" :src="photoURL + item.image" fit="contain"></el-image>
										<div v-show="dialogObj.type != 0" class="huixianImg-hover"><i class="el-icon-delete"></i></div>
									</div>
								</div>
								<el-upload
									:action="baseURL + '/base-api/file/upload'"
									list-type="picture-card"
									:on-success="handlePictureCardPreview"
									:on-remove="handleRemove"
									:limit="imgList2.length > 0 ? 5 - imgList2.length : 5"
									v-if="dialogObj.type != 0"
								>
									<i class="el-icon-plus"></i>
									<div class="el-upload__tip" slot="tip" style="font-weight: bold">最多可以上传5张图片，默认第一张为主图,打勾为新上传图片</div>
								</el-upload>
							</div>
						</template>
					</el-form-item>
				</el-form>
			</div>
			<div class="edit_container">
				<el-radio-group v-model="radioType" style="margin-left: 20px;">
					<el-radio :label="1">PC端</el-radio>
					<el-radio :label="2">APP端</el-radio>
				</el-radio-group>
				<quill-editor v-show="radioType == 1" ref="text" v-model="formData.detailPc" :options="editorOption" @focus="focusEditor($event)" />
				<quill-editor v-show="radioType == 2" ref="texts" v-model="formData.detailApp" :options="editorOption" @focus="focusEditor($event)" />
			</div>
			<el-form ref="form2">
				<el-form-item label="销售状态:" prop="newOld">
					<el-radio-group v-model="formData.sale" :disabled="dialogObj.type == 0">
						<el-radio :label="1">上架销售</el-radio>
						<el-radio :label="0">下架待售</el-radio>
					</el-radio-group>
					<div style="color: red;" v-if="formData.sale == null">销售状态不能为空</div>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close(1)">关闭</el-button>
				<el-button type="primary" @click="submit(1)" v-if="dialogObj.type != 0">确定</el-button>
			</div>
		</div>
		<el-dialog width="10%" title="上传图片" :visible.sync="innerVisible" append-to-body>
			<div style="width: 100%;" class="flex align-center jc-center">
				<el-upload class="avatar-uploader" v-loading="loading2" action="" :show-file-list="false" :before-upload="BeforeUpload" :http-request="Upload">
					<el-image v-if="innerImg" :src="photoURL + innerImg" fit="fill" class="avatar"></el-image>
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close(2)">关闭</el-button>
				<el-button type="primary" @click="submit(2)">确定</el-button>
			</div>
		</el-dialog>
	</el-dialog>
</template>
<script>
import Quill from 'quill';
import { dialogMixin } from '@m/dialogMixin';
import configApi from '@config/configApi';
import { quillEditor } from 'vue-quill-editor';
import resizeImage from 'quill-image-resize-module'; // 图片缩放组件引用
import { ImageDrop } from 'quill-image-drop-module'; // 图片拖动组件引用
Quill.register('modules/imageDrop', ImageDrop); // 注册
Quill.register('modules/resizeImage ', resizeImage); // 注册
const toolbarOptions = [
	['bold', 'italic', 'underline', 'strike'], // 加粗，斜体，下划线，删除线
	['blockquote', 'code-block'], //引用，代码块
	[{ header: 1 }, { header: 2 }], // 几级标题
	[{ list: 'ordered' }, { list: 'bullet' }], // 有序列表，无序列表
	[{ script: 'sub' }, { script: 'super' }], // 下角标，上角标
	[{ indent: '-1' }, { indent: '+1' }], // 缩进
	[{ direction: 'rtl' }], // 文字输入方向
	[{ size: ['small', false, 'large', 'huge'] }], // 字体大小
	[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
	[{ color: [] }, { background: [] }], // 颜色选择
	[{ font: [] }], // 字体
	[{ align: [] }], // 居中
	['clean'], // 清除样式,
	['link', 'image'] // 上传图片、上传视频
];

export default {
	components: {
		quillEditor
	},
	mixins: [dialogMixin],
	data() {
		const validateOrg = (rule, value, callback) => {
			if (!this.formData.provinces.length) {
				callback(new Error('发货城市不能为空'));
			} else {
				callback();
			}
		};
		return {
			innerVisible: false,
			innerImg: '',
			innerIndex: 0,
			title: '',
			loading: false,
			loading2: false,
			loading3: false,
			formData: {
				category1Id: '',
				category2Id: '',
				brandId: '',
				payType: '转账汇款',
				detailPc: '',
				detailApp: '',
				name: '',
				prodDesc: '',
				city: '',
				provinces: [],
				minDay: 0,
				newOld: 1,
				sale: null
			},
			cityOptions: [],
			rules: {
				name: {
					required: true,
					message: '产品名称不能为空',
					trigger: 'blur'
				},
				prodDesc: {
					required: true,
					message: '产品描述不能为空',
					trigger: 'blur'
				},
				minDay: {
					required: true,
					message: '该项为必填项',
					trigger: 'blur'
				},
				category1Id: {
					required: true,
					message: '产品类别不能为空',
					trigger: 'change'
				},
				category2Id: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				sale: {
					required: true,
					message: '此项必填',
					trigger: 'blur'
				},
				brandId: {
					required: true,
					message: '品牌不能为空',
					trigger: 'change'
				},
				provinces: [{ type: 'array', required: true, validator: validateOrg }],
				newOld: {
					required: true,
					message: '新旧状态不能为空',
					trigger: 'change'
				}
			},
			uploadUrl: configApi.photoURL,
			newFile: new FormData(),
			options: [],
			options2: [],
			options3: [],
			priceTypeList: [
				{
					label: '公开',
					value: 1
				},
				{
					label: '洽谈',
					value: 2
				}
			],
			skuList: [],
			imgList: [],
			imgList2: [],
			editorOption: {
				placeholder: '请输入详情页内容...',
				theme: 'snow',
				modules: {
					imageDrop: true, //图片拖拽
					imageResize: {
						//放大缩小
						displayStyles: {
							backgroundColor: 'black',
							border: 'none',
							color: 'white'
						},
						modules: ['Resize', 'DisplaySize', 'Toolbar']
					},
					toolbar: {
						container: toolbarOptions, //工具栏
						handlers: {}
					}
				}
			},
			radioType: 1
		};
	},
	methods: {
		//设置富文本编辑器不可编辑
		focusEditor(event) {
			if (this.dialogObj.type == 0) {
				event.enable(false);
			}
		},
		change1(val) {
			this.addForm = { ...this.addForm };
		},
		//图片上传--移除
		handleRemove(file) {
			this.imgList.pop(file.response);
		},
		//图片上传数组
		handlePictureCardPreview(file) {
			let obj = {
				rentId: '',
				image: file.data,
				isMain: 0
			};
			this.imgList.push(obj);
			// const res = this.imgList.find(ev => {
			// 	return ev.isMain === 1;
			// });
			// if (!res) {
			// 	this.imgList[0].isMain = 1;
			// }
		},
		//上传前校验
		BeforeUpload(file) {
			const isLt2M = file.size / 1024 / 1024 < 10;
			const fileType = file.name.substring(file.name.lastIndexOf('.'));
			if (fileType != '.jpg' && fileType != '.JPG' && fileType != '.PNG' && fileType != '.png') {
				this.$message.error('上传头像图片只能是 JPG,PNG 格式!');
			} else if (!isLt2M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			} else {
				this.currentFileName = file.name;
				if (file) {
					if (this.newFile.has('file')) {
						this.newFile.delete('file');
					}
					this.newFile.append('file', file);
					return true;
				} else {
					return false;
				}
			}
			return false;
		},
		//手动上传
		Upload() {
			this.loading2 = true;
			this.$upload(this.newFile)
				.then(res => {
					if (res.code == 1000) {
						this.innerImg = res.data;
						this.loading2 = false;
						this.newFile = new FormData();
						this.$message.success(res.msg);
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(error => {
					this.$message.error(error);
				});
		},
		deletaImgList2(i) {
			if (this.dialogObj.type == 0) return;
			this.imgList2.splice(i, 1);
		},
		openInnerBox(len, img) {
			if (this.dialogObj.type == 0) return;
			this.innerVisible = true;
			this.innerIndex = len;
			this.innerImg = img;
		},
		//打开弹窗
		open() {
			this.formData = {
				category1Id: '',
				category2Id: '',
				brandId: '',
				payType: '转账汇款',
				detailPc: '',
				detailApp: '',
				name: '',
				prodDesc: '',
				city: '',
				provinces: [],
				minDay: 0,
				newOld: 1,
				sale: null
			};
			this.cityOptions = [];
			this.imgList = [];
			this.imgList2 = [];
			this.skuList = [
				{
					ids: 1,
					img: '',
					number: '',
					price: 0,
					deposit: 0,
					stock: ''
				}
			];
			this.get();
			this.$nextTick(() => {
				this.$refs.form.clearValidate();
			});
		},
		//获取数据

		get() {
			if (this.dialogObj.type == 0 || this.dialogObj.type == 1) {
				if (this.dialogObj.type == 0) {
					this.title = '查看商品';
				} else {
					this.title = '编辑商品';
				}
				this.$get('/shop-api/mgmt/rent/get?id=' + this.dialogObj.id).then(res => {
					if (res.code == 1000) {
						this.formData = res.data.rentEo;
						this.formData.provinces = [];
						if (this.formData.city == '广州') {
							this.formData.provinces[0] = '广东省';
							this.formData.provinces[1] = '广州市';
							this.formData.provinces[2] = '黄埔区';
						} else {
							this.formData.provinces = this.formData.city.split('-');
						}
						this.imgList2 = res.data.imgList;
						this.skuList = res.data.skuList;
						if (this.skuList.length < 1) {
							this.skuList = [
								{
									ids: 1,
									img: '',
									number: '',
									price: 0,
									deposit: 0,
									stock: ''
								}
							];
						}
						this.getProvince(1);
						this.getBrand();
						this.getCategory('02', 1);
						this.getCategory(this.formData.category1Id, 2);
					} else {
						this.$message.error(res.msg);
					}
				});
			} else if (this.dialogObj.type == 2) {
				this.title = '添加商品';
				this.getProvince(0);
				this.options = this.getCategory('02', 1);
				this.getBrand();
			}
		},
		// 类目一选择
		category1IdChange(e) {
			this.getCategory(e, 2);
			this.options2 = [];
			this.formData.category2Id = ''
		},
		//获取分类
		getCategory(e, type) {
			this.$get('/shop-api/category/mgmt/listByPid?pid=' + e).then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				if (type == 1) {
					this.options = res.data;
				} else {
					this.options2 = res.data;
				}
			});
		},
		//获取品牌
		getBrand() {
			this.$get('/shop-api/brand/mgmt/list').then(res => {
				if (res.code != 1000) {
					this.$message.error(res.msg);
					return;
				}
				this.options3 = res.data;
			});
		},

		//提交表单
		submit(e) {
			if (e == 1) {
				let obj = {};
				obj.rent = this.formData;
				obj.imgList = [...this.imgList, ...this.imgList2];
				obj.imgList.forEach((item, i) => {
					if (i == 0) {
						item.isMain = 1;
					} else {
						item.isMain = 0;
					}
				});
				obj.skuList = this.skuList;
				if (this.formData.sale == null) {
					this.$message.error('销售状态不能为空');
					return;
				}
				this.$refs['form'].validate(valid => {
					if (valid) {
						this.loading = true;
						this.$postJson('/shop-api/mgmt/rent/addOrUpdate', obj).then(res => {
							this.loading = false;
							if (res.code != 1000) {
								this.$message.error(res.msg);
								return;
							}
							this.$message.success(res.msg);
							this.$parent.list();
							this.close(1);
						});
					} else {
						this.$message.error('必填项不能为空');
						return false;
					}
				});
			} else {
				this.skuList[this.innerIndex].img = this.innerImg;
				this.innerVisible = false;
			}
		},
		CityChange(value) {
			// this.formData.province = value[0];
			// this.formData.city = value[1];
			// this.formData.district = value[2];
			this.formData.city = `${value[0]}-${value[1]}-${value[2]}`;
		},
		// 获取省市区地址
		getProvince(e) {
			// 获取省市区地址
			this.$get('base-api/area/tree').then(data => {
				this.cityOptions = this.getTreeData(data.data);
				if (e == 1) {
					this.$nextTick(() => {
						let i, j, k;
						for (i = 0; i < this.cityOptions.length; i++) {
							if (this.cityOptions[i].name === this.formData.provinces[0]) {
								this.$set(this.formData.provinces, 0, this.cityOptions[i].name);
								break;
							}
						}
						for (j = 0; j < this.cityOptions[i].child.length; j++) {
							if (this.cityOptions[i].child[j].name === this.formData.provinces[1]) {
								this.$set(this.formData.provinces, 1, this.cityOptions[i].child[j].name);
								break;
							}
						}
						for (k = 0; k < this.cityOptions[i].child[j].child.length; k++) {
							if (this.cityOptions[i].child[j].child[k].name === this.formData.provinces[2]) {
								this.$set(this.formData.provinces, 2, this.cityOptions[i].child[j].child[k].name);
								break;
							}
						}
					});
				}
			});
		},
		// 递归判断列表，把最后的children设为undefined
		getTreeData(data) {
			for (var i = 0; i < data.length; i++) {
				if (data[i].child.length < 1) {
					// children若为空数组，则将children设为undefined
					data[i].child = undefined;
				} else {
					// children若不为空数组，则继续 递归调用 本方法
					this.getTreeData(data[i].child);
				}
			}
			return data;
		},
		//关闭弹窗
		close(e) {
			if (e == 1) {
				this.visiable = false;
			} else {
				this.innerVisible = false;
			}
		},
		//添加一行
		addList() {
			let obj = {
				img: '',
				number: '',
				price: 0,
				deposit: 0,
				stock: ''
			};
			this.skuList.push(obj);
		},
		//删除一行
		remove(row) {
			this.skuList = this.skuList.filter(item => item.number != row.number);
			if(this.skuList.length==0){
				this.addList()
			}
		}
	}
};
</script>
<style type="text/css">
.edit_container,
.quill-editor {
	margin: 20px;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
	content: '14px';
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
	content: '10px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
	content: '18px';
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
	content: '32px';
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
	content: '文本';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='1']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='1']::before {
	content: '标题1';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='2']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='2']::before {
	content: '标题2';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='3']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='3']::before {
	content: '标题3';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='4']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='4']::before {
	content: '标题4';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='5']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='5']::before {
	content: '标题5';
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value='6']::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value='6']::before {
	content: '标题6';
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
	content: '标准字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='serif']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='serif']::before {
	content: '衬线字体';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='monospace']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='monospace']::before {
	content: '等宽字体';
}
</style>
<style lang="scss" scoped>
.dialog {
	::v-deep.cascader {
		display: block;
	}
}

::v-deep.avatar-uploader .el-upload {
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	position: relative;
	overflow: hidden;
}
::v-deep.avatar-uploader .el-upload:hover {
	border-color: #409eff;
}

.avatar-uploader-icon {
	font-size: 28px;
	color: #8c939d;
	width: 138px;
	height: 138px;
	line-height: 138px;
	text-align: center;
}
.avatar {
	width: 138px;
	height: 138px;
	display: block;
}

.dia-title {
	width: 100%;
	height: 40px;
	line-height: 40px;
	font-size: 14px;
	padding: 0 30px;
	background-color: aliceblue;
}

.imgList {
	width: 100%;
	padding: 20px;
	flex-wrap: wrap;
	.avatar {
		width: 168px;
		height: 168px;
		display: block;
		margin-right: 10px;
	}
}
.imgList-upload {
	padding: 20px;
}
.huixianImgBox {
	display: inline-block;
	position: relative;
}
.huixianImg {
	width: 148px;
	height: 148px;
	margin-right: 8px;
	border: 1px solid #c0ccda;
	border-radius: 6px;
}
.huixianImgBox:hover .huixianImg-hover {
	display: block;
}

.huixianImg-hover {
	position: absolute;
	top: 0;
	left: 0;
	width: 148px;
	height: 148px;
	background-color: rgba(0, 0, 0, 0.5);
	display: none;
	border-radius: 6px;
	i {
		color: #ffffff;
		font-size: 20px;
		text-align: center;
		line-height: 148px;
	}
}
 ::v-deep input::-webkit-outer-spin-button,
   ::v-deep input::-webkit-inner-spin-button {
       -webkit-appearance: none !important;
     }
   ::v-deep input[type='number'] {
       -moz-appearance: textfield !important;
     }

</style>
